
@mixin butt {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border: none;
  padding: 14px 15px;
  @include transition(.2s ease-in-out);
  font-weight: 400;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.2em;
  background-color: $color-accent-secondary;
  color: #fff;
  @include rounded(2px);
  //@include drop-shadow(0, .3, 30px, 0);
}

.butt,
a.butt:active,
a.butt:hover,
a.butt:link,
a.butt:visited,
button,
input.button {
  @include butt;
}

@mixin butt-hover {
  background-color: $color-accent-third;
  color: #fff;
}

a:hover.butt,
button:hover,
input.button:hover {
  @include butt-hover;
}
