@media all and (-ms-high-contrast:none)
 {
   .footer.footer {
     background: transparent !important;
     border: 1px solid $color-hr;
   }
 }

.footer.footer {
  //background: #0F273D;
  background: url("#{$images}/wave.svg") center top no-repeat;
  background-size: 105% auto;
  background-position: center top;
  margin: 100px 0 0 0;
  padding: 30px;
  text-align: left;

  @media handheld, only screen and (min-width: 1500px) {
    padding-top: 80px;
  }

  .footer-inner {
    padding-top: 60px;
    padding-bottom: 0px;
  }
  &, p, ul li {
    font-size: 14px;
  }

  h3 {
    font-size: 15px;
    letter-spacing: .1em;
    text-transform: uppercase;
    margin-bottom: 9px;
    font-family: $font-body;
    font-weight: 500;
  }

  p {
    margin: 0;
  }
  a.butt {
    padding: 12px;
  }
  a.butt {
    background: $color-accent-third;
  }
  a.butt:hover {
    background: $color-accent-secondary;
  }

  .left {
    text-align: left;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
    a.butt {
      background: $color-accent-third;
    }
    a.butt:hover {
      background: $color-accent-secondary;
    }
  }
  .right {
    text-align: right;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }


  .legal {
    margin-top: 30px;
    //text-transform: uppercase;
    //letter-spacing: .09em;
    font-size: 14px;
    line-height: 120%;
    text-align: left;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
    p {
      margin: 0;
    }
    p, &, a {
      color: rgba(0, 0, 0, .2);
    }
    a:hover {
      color: $color-accent-third;
    }
  }


  .sn {
    text-align: left;
    margin-top: 25px;
    @include media-breakpoint-down(sm) {
      text-align: center;
      @include flex-justify-center;
    }
    .svg & {
      @include flex-it;
      @include flex-align-item-start;
      text-align: left;
      @include media-breakpoint-down(sm) {
        text-align: center;
        @include flex-align-item-center;
      }
    }
    a {
      .svg & {
        background-color: $color-accent-secondary;
        width: 40px;
        height: 40px;
        @include rounded(25px);
        @include flex-it;
        @include flex-align-item-center;
        svg {
          margin: 0 auto;
          .st0 {
            fill: #fff;
          }
        }
        &:hover {
          background-color: $color-accent-third;
          svg {
            .st0 {
              fill: #fff;
            }
          }
        }
      }
    }
  }
}
