


.team {
  .employee {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    h3 {
      margin-bottom: 4px;
    }
    .title {
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: .1em;
    }
    a.butt {
      margin-top: 10px;
    }
    img {
      max-width: 150px;
      margin: 0 auto 20px;
    }
  }
}

section.filters {
  text-align: center;
  .facet-reset {
    @include opacity(0);
    &.inplace {
      @include opacity(1);
    }
  }

  .reset-filters {
    margin: 0;
  }

  .facetwp-type-autocomplete {
    input {
      margin-right: 2px !important;
    }
  }

}
