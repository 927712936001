.page-heading {
  margin: 50px 0;

  .col {
    @include flex-it;
    @include flex-align-item-center;
    @include flex-justify-center;

    h1 {
      font-family: $font-menu;
      font-size: 17px;

      letter-spacing: 0.08em;
      margin: 0;
      color: $color-base;

      html.wf-active & {
        font-size: 24px;
        line-height: 100%;
        text-transform: uppercase;
        letter-spacing: 0.08em;
      }
    }
    span {
      height: 50px;
      width: 1px;
      border-left: 1px solid $color-hr;
      margin: 0 20px;
    }
    h2 {
      font-size: 34px;
      margin: 0;
      line-height: 100%;
    }

    @include media-breakpoint-down(sm) {
      display: block;
      text-align: center;
      span {
        width: 50px;
        height: 1px;
        border: 0;
        border-top: 1px solid $color-hr;
        margin: 20px auto;
        display: block
      }
    }
  }

}



body.single-lab_portfolio {
  .page-title {
    background-color: $color-base;
    .overlay {
      background: rgba(0, 0, 0, .25);
    }
  }
}

.page-title {
  background: rgba(6, 42, 61, .9) url("#{$images}/loading.gif") center center no-repeat;
  background-size: 60px 60px;
  clear: both;

  .overlay {
    height: 450px;
    text-align: center;
    background: rgba(6, 42, 61, .25);
    &.no-content {
      background: transparent;
    }
    @include flex-it;
    @include flex-align-item-center;

    .flex-container {
      margin: 0 auto;
    }

    @media handheld, only screen and (max-width: 768px) {

    }


    h1,
    h2,
    h3,
    h4,
    p {
      color: #fff;
    }


    h1 {
      margin: 0 25px;
      padding: 0;
    }

    p {
      margin: 15px 25px 0;
    }
  }
}

html {
  .page-title {
      &.inplace {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

      }
  }
}




section.intro-grid {
  .grid-item {
    background-size: cover;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  a {
    background: rgba(0, 0, 0, .6);
    display: block;
    min-height: 300px;
    @include flex-it;
    @include flex-align-item-center;
    @include flex-justify-center;
    color: #fff;
    &:hover {
      background-color: rgba(29, 131,  19, .7);
    }
  }
  p, h1, h2, h3, h4, h5 {
    margin: 0;
  }
}






section.grid.timeline {
  .row {
    border-top: 1px solid $color-hr;
    padding: 0;
    .grid-item {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      //@include drop-shadow(0, 0.1, 5px, 1px);
      padding: 50px;
      border-bottom: 1px solid $color-hr;
      border-left: 1px solid $color-hr;
      //border-right: 1px solid $color-hr;
    }

    .grid-item:nth-child(3n-3) {
      border-right: 1px solid $color-hr;
    }
    .grid-item:last-child {
      border-right: 1px solid $color-hr;
    }
  }
}


section.locations-grid {
  p {
    line-height: 135%;
  }
}




section.fcslider.intro {
  .flexslider {
    img {
      display: block;
      max-width: 480px;

      height: auto;
      //height: auto;
      //margin: 0 auto;
    }

    &,
    ul.slides,
    ul.slides li {
      height: 600px;
      @media handheld, only screen and (max-width: 991px) {
        height: 500px;
      }
      @media handheld, only screen and (max-width: 768px) {
        height: 430px;
      }
    }

  }
}
