$color-base: lighten(#000, 10%);
$color-text: lighten(#000, 15%);
$color-subtle: rgba(0, 0, 0, .4);
$color-accent: #22609f;
$color-admin-accent: #df272c;
$color-headings: $color-accent;
$color-accent-secondary: #df272c;
$color-accent-third: #63C2DE;
$color-overlay-rgba: rgba(33, 56, 91, .35);
$color-neutral: #A0A0A0;
$color-body-grey: #B5B5B5;
$color-light-text: #B5B5B5;
$color-light-text-forms: rgba(0, 0, 0, .55);
$color-light: #EFEFEF;
$color-grey: #E5E5E5;
$color-subtle-background: #F7F5F2;
$color-subtle-background-secondary: #EBF3F7;
$color-on-base: #889998;
$color-grey-on-base: #637575;
$color-light-box: #F7F7F7;
$color-hr: #D7DDD9;
$color-hamburger: $color-base;
$color-hamburger-menu-open: #fff;

$font-sans:	"proxima-nova", sans-serif;
$font-serif:"proxima-nova", sans-serif;
$font-body: "proxima-nova", sans-serif;
$font-headline: "museo-slab", sans-serif;
$font-menu: "franklin-gothic-ext-comp-urw", sans-serif;
$images: "../../assets/images";
